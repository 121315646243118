<template>
  <div class="container-stats">
    <div class="container-statistics">
      <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
        <!--<li>
          <router-link to="/solutions/in/platform/models">
            <span>Model</span>
          </router-link>
        </li>-->
        <li class="ProximaNovaBold">Model Results</li>
      </ul>
      <div class="d-flex flex-row" style="height: 40%">
        <div class="w-100 pt-4">
          <w-white-dropdown
            :options="brandDropdown"
            :placeHolder="'Select'"
            :selectedOption="selectedBrand"
            :labelText="'Brand'"
            style="float: right"
            class="mr-3"
            @input="selectBrand($event)"
          ></w-white-dropdown>
        </div>

        <div class="pt-4">
          <w-white-dropdown
            :options="mediaDropdown"
            :placeHolder="'Select'"
            :selectedOption="selectedMedia"
            :labelText="'KPI'"
            class="mr-3"
            @input="selectMedia($event)"
          ></w-white-dropdown>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">Model Results</div>
      </div>
      <div v-for="(stats, index) in allStatsData" :key="index">
        <div class="w-100 my-3">
          <h3 class="ProximaNovaBold">{{ stats.name }}</h3>
        </div>
        <div class="w-100 d-flex">
          <w-chart-overview :chartSummary="stats.value"></w-chart-overview>
          <div class="icon d-none">
            <img
              src="@/assets/eye-line.png"
              alt="hide"
              v-if="activeModel === index"
              @click="showChartData(stats, index)"
            />
            <img
              src="@/assets/eye-close-line bold.svg"
              @click="showChartData(stats, index)"
              alt="view"
              v-else
            />
          </div>
        </div>
      </div>
    </div>

    <div class="container-line-chart d-none" v-if="activeModel !== 'none'">
      <div class="title">Actual vs Prediction of {{ modelName }}</div>

      <ColumnChart :chartData="columnChartData" />
    </div>

    <div class="text-right my-5">
      <w-button
        :buttonText="'Progress'"
        class="my-5"
        :buttonLoader="'normal'"
        @buttonClicked="nextToTheInsights"
      >
      </w-button>
    </div>
    <page-loader v-if="count < 1"></page-loader>
  </div>
</template>
<script>
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import PageLoader from "../../../widgets/PageLoader.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
import Button from "@/components/Profile/Button.vue";
// import modelsSummary from "@/response/modelsSummary.json"
const aimoSolutionsServices = new AimoSolutionsServices();
const aimoUnileverServices = new AimoUnileverServices();

export default {
  name: "Stats",
  components: {
    PageLoader,
    ColumnChart,
    "w-button": Button,
    "w-white-dropdown": WhiteDropdown,
    "w-chart-overview": ChartOverview,
  },
  data() {
    return {
      clientId: sessionStorage.getItem("subId"),
      count: 0,
      modelName: "",
      marketType: localStorage.getItem("selectedKPISource"),
      selectedBrandKPI: localStorage.getItem("selectedBrandKPI"),
      activeModel: "none",
      selectedstat: "",
      statsDropdown: [
        { text: "Elite", id: "elite" },
        { text: "Pro", id: "pro" },
        { text: "Lite Plan", id: "Lite Plan" },
      ],
      selectedBrand: {
        text: localStorage.getItem("selectedBrandKPI"),
        id: localStorage.getItem("selectedBrandKPI").toLowerCase(),
      },
      brandDropdown: [
        { text: "Tresemme", id: "tresemme" },
        { text: "Dove", id: "dove" },
      ],
      mediaDropdown: [
        { text: "Offline", id: "offline" },
        { text: "Online", id: "online" },
        { text: "Overall", id: "overall" },
      ],
      selectedMedia: {
        text: localStorage.getItem("selectedMediaKPI"),
        id: localStorage.getItem("selectedMediaKPI").toLowerCase(),
      },
      columnChartData: {
        data: [
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Investment vs ROI by Channel(Cr) from 01-SEPT-2021 to 15-NOV-2021",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      salseStatics: [
        { name: "R2_Score", value: "0.86", className: "blue-pastel" },
        { name: "Actual_Mean", value: "21.05M", className: "green-pastel" },
        { name: "Predicted_Mean", value: "21.91M", className: "pink-pastel" },
        { name: "Actual_Std", value: "17.62M", className: "red-pastel" },
        { name: "Predicted_Std", value: "21.84M", className: "yellow-pastel" },
      ],
      allStatsData: [],
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
    };
  },
  methods: {
    saveKPISourceSelected() {
      localStorage.setItem(
        "selectedKPISource",
        `${this.selectedBrand.id}_${this.selectedMedia.id}`
      );
      this.marketType = `${this.selectedBrand.id}_${this.selectedMedia.id}`;
      localStorage.setItem("selectedBrandKPI", this.selectedBrand.text);
      this.getAllModel();
    },
    selectBrand(e) {
      this.selectedBrand = e;
      this.saveKPISourceSelected();
      // this.getAllModel()
    },
    selectMedia(e) {
      this.selectedMedia = e;
      this.saveKPISourceSelected();
      // this.addAllTimeData();
      // this.addsalseStatics();
      // this.saveToLocal();
      // this.callApis();
    },
    getStaticsData() {
      this.salseStatics = [];
      this.allStatsData = [];
      aimoSolutionsServices
        .getChartData(
          "actual vs prediction",
          "/b2c/in/",
          "salesstatistics",
          "no"
        )
        .then((res) => {
          for (var j = 1; j < 6; j++) {
            var salseStatics = [];
            for (var i = 0; i < res.length; i++) {
              salseStatics.push({
                name: res[i].name,
                value: res[i].value,
                className: this.classess[i],
                image: res[i].name + ".svg",
              });
            }
            this.allStatsData.push({ name: "Model " + j, value: salseStatics });
          }

          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getChartData() {
      this.columnChartData.data[0].data = [];
      this.columnChartData.xAxis.categories = [];
      aimoSolutionsServices
        .getChartData(
          "actual vs prediction",
          "/b2c/in/",
          "actualvsprediction",
          "no"
        )
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            this.columnChartData.data[0].data.push(res[i].value);
            this.columnChartData.xAxis.categories.push(res[i].name);
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getAllModel() {
      this.plans = [];
      aimoUnileverServices
        .getChartData(this.selectedBrand.text, this.selectedMedia.id, "modelsummary", this.clientId)
        .then((res) => {
          var res_data = res.response
          this.count++;
          this.allStatsData = [];
          for (var j = 0; j < res_data.length; j++) {
            var salseStatics = [];
            for (var i = 0; i < res_data[j].statics.length; i++) {
              salseStatics.push({
                name: res_data[j].statics[i].name,
                value: res_data[j].statics[i].value,
                className: this.classess[i],
                image: res_data[j].statics[i].name + ".svg",
              });
            }
            this.allStatsData.push({
              name: res_data[j].title,
              value: salseStatics,
              chartData: res_data[j].value,
            });
            // if (j === 0) {
            //   this.modelName = res[j].title;
            //   this.showChartData(this.allStatsData[0], 0);
            // }
          }
        })
        .catch(() => {
          this.count++;
        });
    },
    showChartData(data, index) {
      if (this.activeModel === index) {
        this.activeModel = "none";
        return;
      }
      this.activeModel = index;
      this.modelName = data.title;
      this.columnChartData.data[0].data = [];
      this.columnChartData.xAxis.categories = [];
      for (var i = 0; i < data.chartData.length; i++) {
        this.columnChartData.data[0].data.push(data.chartData[i].value);
        this.columnChartData.xAxis.categories.push(data.chartData[i].name);
      }
    },
    selectOption(e) {
      this.selectedstat = e;
    },
    nextToTheInsights() {
      this.$router.push("/solutions/ul/platform/attribution");
    },
  },
  created() {
    //  this.getStaticsData();
    //  this.getChartData();
    this.getAllModel();
  },
};
</script>

<style lang="css" scoped>
.container-stats {
  width: 93%;
  margin: 71px auto 0 auto;
}
.icon {
  align-items: center;
  background: #fff;
  padding-right: 10px;
}

.container-statistics {
  padding-top: 1px;
}

.title {
  font-family: ProximaNovaBold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  margin-top: 48px;
  margin-bottom: 32px;
}

.wrapper-statistics {
  box-shadow: 0px 0px 16px -13px rgb(0 0 0);
  border-radius: 4px;
  min-height: 126px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.item {
  display: flex;
  width: 20%;
  position: relative;
}

.item:not(:last-child):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>
